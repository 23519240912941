import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import {
	BuyersIntent,
	Company,
	CompanyCountry,
	CompanyIndustry,
	Country,
	Industry,
	JobTitle,
	Location,
	Region,
	Subregion,
	Technographics,
} from 'shared/generated-models';

export const dictionaryService = createApi({
	reducerPath: 'dictionaryService',
	baseQuery: interceptor,
	endpoints: builder => ({
		getJobTitles: builder.query<JobTitle[], string>({
			query: search => ({
				url: `/jobtitles/?search=${search}`,
				method: 'GET'
			})
		}),
		getCompanies: builder.query<Company[], string>({
			query: search => ({
				url: `/companies/?search=${search}`,
				method: 'GET'
			})
		}),
		getCompanyCountries: builder.query<CompanyCountry[], string>({
			query: search => ({
				url: `/company_countries/?search=${search}`,
				method: 'GET'
			})
		}),
		getCompanyIndustries: builder.query<CompanyIndustry[], string>({
			query: search => ({
				url: `/company_industries/?search=${search}`,
				method: 'GET'
			})
		}),
		getCountries: builder.query<Country[], string>({
			query: search => ({
				url: `/countries/?search=${search}`,
				method: 'GET'
			})
		}),
		getIndustries: builder.query<Industry[], string>({
			query: search => ({
				url: `/industries/?search=${search}`,
				method: 'GET'
			})
		}),
		getLocations: builder.query<Location[], string>({
			query: search => ({
				url: `/locations/?search=${search}`,
				method: 'GET'
			})
		}),
		getRegions: builder.query<Region[], string>({
			query: search => ({
				url: `/regions/?search=${search}`,
				method: 'GET'
			})
		}),
		getSubregions: builder.query<Subregion[], string>({
			query: search => ({
				url: `/subregions/?search=${search}`,
				method: 'GET'
			})
		}),
		getBuyersIntent: builder.query<BuyersIntent[], string>({
			query: search => ({
				url: `/bombora_terms/?search=${search}`,
				method: 'GET'
			})
		}),
		getTechnographics: builder.query<Technographics[], string>({
			query: search => ({
				url: `/technography/?search=${search}`,
				method: 'GET'
			})
		}),
	})
});

export const {
	useGetJobTitlesQuery,
	useGetCompaniesQuery,
	useGetCompanyCountriesQuery,
	useGetCompanyIndustriesQuery,
	useGetCountriesQuery,
	useGetIndustriesQuery,
	useGetLocationsQuery,
	useGetRegionsQuery,
	useGetSubregionsQuery,
	useGetBuyersIntentQuery,
	useGetTechnographicsQuery,
} = dictionaryService;
