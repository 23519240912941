import { ReactNode, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getTokens, useGetCurrentUserQuery } from 'services';
import { environment } from 'environments/environment';
// import { OutOfCreditsModal } from 'features/auth';

export const IntercomWrapper = ({ children }: { children: ReactNode }) => {
	const { data } = useGetCurrentUserQuery();

	useEffect(() => {
		if (!data) return;

		window.Intercom('boot', {
			app_id: environment.intercomId,
			email: data.email,
			name: `${data.first_name} ${data.last_name}`,
			user_id: data.id.toString(),
			company_name: data.company,
			billing_plan: data.billing_plan,
			avatar: data.avatar
				? {
						type: 'avatar',
						image_url: data.avatar
				  }
				: undefined
		});

		return () => {
			window.Intercom('shutdown');
		};
	}, [data]);

	return <>{children}</>;
};

export const ProtectedRoute = () => {
	const tokens = getTokens();

// 	return !tokens.accessToken ? (
// 		<Navigate to="/login" />
// 	) : (
// 		<>
// 			<Outlet />
// 			<OutOfCreditsModal />
// 		</>
// 	);

	return !tokens.accessToken ? (
		<Navigate to="/login" />
	) : (
		<IntercomWrapper>
			<Outlet />
			{/* <OutOfCreditsModal /> */}
		</IntercomWrapper>
	);
};
