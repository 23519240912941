import React, { FC, useRef, useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useFormikContext } from 'formik';

import { SearchFormParams } from 'features/search/models';
import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Icon, Text } from 'shared/components/ui';
import { useFileContext } from 'features/auth';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
}

export const CompanyDomainModal: FC<Props> = ({ onClose }: Props) => {
	const fileInputRef = useRef(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const { contextValue, setContextValue } = useFileContext();

	const { values, setValues } = useFormikContext<SearchFormParams>();

	const addCompanyDomains = (name: string, domains: string[]) => {
		const nonEmptyDomains = domains.filter(domain => domain !== "");

		setContextValue({
			name: name,
			domains: nonEmptyDomains,
		  });

		// if ('company_domains' in values && values['company_domains']) {
		// 	const updatedDomains = Array.from(new Set([...values['company_domains'], ...nonEmptyDomains]));
		// 	console.log(updatedDomains)
		// 	setValues({ ...values, company_domains: updatedDomains });
		// } else {
		// 	setValues({ ...values, company_domains: nonEmptyDomains });
		// }
        onClose();
	};

	// @ts-ignore
	const handleFileV2 = async event => {
		setLoading(true);
		const file = event.target.files[0];
		if (file) {
			if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.csv')) {
				setErrorMessage('Please upload a valid CSV or XLSX file');
				setLoading(false);
				return;
			}

			const fileSize = file.size / (1024 * 1024);
			if (fileSize > 50) {
				setErrorMessage('File size must be less than 50 MB');
				setLoading(false);
				return;
			}

			setErrorMessage('');

			try {
				let columnData = [];
				if (file.name.endsWith('.csv')) {
					// Parse CSV file
					Papa.parse(file, {
						header: true, //@ts-ignore
						complete: results => {
							const data = results.data;
							//@ts-ignore
							const columnName = data[0].hasOwnProperty('domain')
								? 'domain' //@ts-ignore
								: data[0].hasOwnProperty('domains')
								? 'domains'
								: null;

							if (columnName) {
								//@ts-ignore
								columnData = data.map(row => row[columnName]);
                                addCompanyDomains(file.name, columnData);
							} else {
								setErrorMessage('No "domain" or "domains" column found');
							}
						}
					});
				} else if (file.name.endsWith('.xlsx')) {
					// Parse XLSX file
					const reader = new FileReader();
					reader.onload = e => {
						//@ts-ignore
						const data = new Uint8Array(e.target.result);
						const workbook = XLSX.read(data, { type: 'array' });
						const sheetName = workbook.SheetNames[0];
						const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
							header: 1
						});
						// Get header row
						const headers = worksheet[0];
						//@ts-ignore
						const domainIndex = headers.indexOf('domain');
						//@ts-ignore
						const domainsIndex = headers.indexOf('domains');

						if (domainIndex !== -1 || domainsIndex !== -1) {
							const columnIndex = domainIndex !== -1 ? domainIndex : domainsIndex;
							//@ts-ignore
							columnData = worksheet.slice(1).map(row => row[columnIndex]);
                            addCompanyDomains(file.name, columnData);
						} else {
							setErrorMessage('No "domain" or "domains" column found');
						}
					};
					reader.readAsArrayBuffer(file);
				}
			} catch (error) {
				console.error('Error parsing file:', error);
				setErrorMessage('Error processing file');
			} finally {
				setLoading(false);
			}
		}
	};

	const triggerFileInput = () => {
		// @ts-ignore
		fileInputRef.current.click();
	};

	return (
		<CenterModal onClose={onClose} title={'Find employees by company domains'} iconColor="green">
			<div className={styles.body}>
				<Text variant="inter/16/regular">
					Upload a file with a list of companies' domains, and Muraena will find contacts of their employees.
					Some recommendations:
				</Text>
				<div className={styles.project}>
					<div className={styles.projectTop}>
						<Text variant="inter/16/medium">Supported file formats:</Text>
						<Text variant="inter/16/medium" color="grey">
							.csv
						</Text>
					</div>
					<div className={styles.projectTop}>
						<Text variant="inter/16/medium">Required column title:</Text>
						<Text variant="inter/16/medium" color="grey">
							domain
						</Text>
					</div>
					<div className={styles.projectTop}>
						<Text variant="inter/16/medium">Required format:</Text>
						<Text variant="inter/16/medium" color="grey">
							https://domain.com, or domain.com
						</Text>
					</div>
					{errorMessage && (
						<Text variant="inter/16/medium" color="red">
							{errorMessage}
						</Text>
					)}
				</div>
			</div>
			<ModalFooter>
				<Button onClick={onClose} type="ghost">
					Cancel
				</Button>
				<Button
					loading={loading}
					prefix={<Icon icon="attachment" size={20} />}
					onClick={triggerFileInput}
					type="primary">
					Browse files
				</Button>
				<input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileV2} />
			</ModalFooter>
		</CenterModal>
	);
};
