import { Outlet } from 'react-router-dom';

import { Header, Navbar } from 'shared/components';

import styles from './index.module.scss';

export const MainLayout = () => {
	return (
		<>
			<div className={styles.layout}>
				<Header className={styles.header} />
				<Navbar className={styles.sidebar} />
				<main className={styles.content}>
					<Outlet />
				</main>
			</div>
		</>
	);
};
